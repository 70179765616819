import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';

const StyledContainer = styled.div`
  display: grid;
  background-color: rgb(209 213 219);
  height: auto;
`

const StyledDiv = styled.div`
  background-color: rgba(255,255,255,0.8);
`

const StyledVideo = styled.video`
  display: grid;
  width: 100%;
  height: 100%;
`

const pStyle = "text-base md:text-xl mb-6"

const SectionCaptiveMicrocaptive = () => {
  const data = useStaticQuery(graphql`
    query SectionCaptiveMicrocaptiveQuery {
      prismicHomePage {
        data {
          captive_and_micro_captive_section_title
          captive_and_micro_captive_section_text {
            richText
          }
          captive_and_micro_captive_section_button_text
          captive_and_micro_captive_section_button_link {
            link_type
            uid
            url
          }
        }
      }
    }
  `)

  const doc = data.prismicHomePage

  return (
    <div className="bg-site-green-light pt-4 md:pt-24 2xl:pt-32 3xl:pt-56 pb-12 md:pb-24">
      <StyledDiv className="relative w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center bg-white  mx-auto p-8 rounded-3xl shadow-lg">
        <div className="absolute top-0 bg-site-green py-4 px-6 md:px-16 rounded-full -mt-8 mx-auto">
          <h2 className="text-white text-base md:text-2xl font-bold text-center">{doc.data.captive_and_micro_captive_section_title ? doc.data.captive_and_micro_captive_section_title : "Section Title"}</h2>
        </div>

        <div className="green-bubble-slice-section w-full max-w-4xl mt-12">
          {doc.data.captive_and_micro_captive_section_text.richText
            ?
            <RichText render={doc.data.captive_and_micro_captive_section_text.richText} htmlSerializer={htmlSerializer} />
            :
            <p className={pStyle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          }
        </div>

        <div className="mt-4">
          <Link to={doc.data.captive_and_micro_captive_section_button_link.uid ? `/${doc.data.captive_and_micro_captive_section_button_link.uid}` : "/"}>
            <button id="qualify-button" className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-12 py-3">
              {doc.data.captive_and_micro_captive_section_button_text ? doc.data.captive_and_micro_captive_section_button_text : "Button Name"}
            </button>
          </Link>
        </div>
      </StyledDiv>
    </div>    
  )
}

export default SectionCaptiveMicrocaptive