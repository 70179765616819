import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { navigate } from '@reach/router'
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledGatsbyImage = styled(GatsbyImage)`
  min-height: 800px;

  @media (min-width: 1280px) {
    min-height: 600px;
  }

  @media (max-width: 767px) {
    min-height: 800px;
  }
`

const HeroHomeUpdateNewVersion = ({ agentPage }) => {
  const data = useStaticQuery(graphql`
    query HeroHomeUpdateNewVersionQuery {
      prismicHomePage {
        data {
          hero_title
          hero_subtitle
          button_name_1
          button_name_1_hover
          button_link_1 {
            uid
          }
          button_one_main_color
          button_one_text_color
          button_hover_color
          button_one_hover_text_color
          button_name_2
          button_link_2 {
            uid
          }
          button_two_main_color
          button_two_text_color
          button_two_hover_color
          button_two_hover_text_color
          button_two_link_type
          hero_subtitle_agent
          hero_logo {
            gatsbyImageData(
              width: 307
            )
          }
          hero_background_image {
            gatsbyImageData(
              width: 1920
            )
          }
        }
      }
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const doc = data.prismicHomePage
  const heroBgImage = getImage(doc.data.hero_background_image)
  const heroBgPlaceholder = getImage(data.imgDefault)
  
  const buttonOneColor = doc.data.button_one_main_color ? doc.data.button_one_main_color : 'bb0000'
  const buttonOneTextColor = doc.data.button_one_text_color ? doc.data.button_one_text_color : 'ffffff'
  const buttonOneHoverColor = doc.data.button_hover_color ? doc.data.button_hover_color : 'cf1716'
  const buttonOneHoverTextColor = doc.data.button_one_hover_text_color ? doc.data.button_one_hover_text_color : 'ffffff'

  const buttonTwoColor = doc.data.button_two_main_color ? doc.data.button_two_main_color : 'ffffff'
  const buttonTwoTextColor = doc.data.button_two_text_color ? doc.data.button_two_text_color : 'bb0000'
  const buttonTwoHoverColor = doc.data.button_two_hover_color ? doc.data.button_two_hover_color : 'ffffff'
  const buttonTwoHoverTextColor = doc.data.button_two_hover_text_color ? doc.data.button_two_hover_text_color : 'bb0000'

  const StyledButtonOne = styled.button`
    background: #${buttonOneColor};
    color: #${buttonOneTextColor};

    :hover {
      background: #${buttonOneHoverColor};
      color: #${buttonOneHoverTextColor};
    }
  `

  const StyledButtonTwo = styled.button`
  background: #${buttonTwoColor};
  color: #${buttonTwoTextColor};

  :hover {
    background: #${buttonTwoHoverColor};
    color: #${buttonTwoHoverTextColor};
  }
  `

  return (
    <>
      <div className="relative md:pt-14 xl:pt-20" style={{ display: "grid", background: "#423E42" }}>
        <StyledGatsbyImage
          style={{
            gridArea: "1/1",
            objectFit: "cover",
            opacity: "0.6",
          }}
          alt="hero background"
          image={heroBgImage}
          loading="eager"
          placeholder="none"
        />
        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="w-11/12 xl:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2 mt-12 md:-mt-12">
            <h2 className="text-white text-3xl md:text-6xl font-extrabold text-center uppercase xl:mt-12">
              {doc.data.hero_title ? doc.data.hero_title : 'HERO TITLE'}
            </h2>

            <h3 className="max-w-2xl text-white text-2xl md:text-3xl font-bold text-center mt-6">
              {agentPage
                ?
                doc.data.hero_subtitle_agent ? doc.data.hero_subtitle_agent : 'Hero subtitle in here.'
                :
                doc.data.hero_subtitle ? doc.data.hero_subtitle : 'Hero subtitle in here.'
              }
            </h3>

            <div className="flex flex-col xl:flex-row items-center justify-center mt-8 space-x-0 md:space-x-6">
              {agentPage
                ?
                <StyledButtonOne
                  onClick={() => {navigate('#sra-plans')}}
                  className="w-full md:w-64 text-base md:text-lg text-white uppercase tracking-wider px-16 py-3"
                >
                  {doc.data.button_name_1 ? doc.data.button_name_1 : "Button Name"}
                </StyledButtonOne>
                :
                <>
                  <div className="hidden xl:flex flex-col items-center justify-center">
                    <Link to={doc.data.button_link_1.uid ? `/${doc.data.button_link_1.uid}` : "/"}>
                      <StyledButtonOne id="qualify-button" className="w-full md:w-auto text-base md:text-lg text-white uppercase tracking-wider px-12 py-3">
                        <span className="default-text mx-2">{doc.data.button_name_1}</span>
                        <span className="hover-text">{doc.data.button_name_1_hover}</span>
                      </StyledButtonOne>
                    </Link>
                  </div>

                  <div className="block xl:hidden mb-6">
                    <p className="text-white text-2xl font-semibold text-center tracking-wider mb-6">Do You Qualify?</p>
                    <Link to="/qualify">
                      <StyledButtonOne id="qualify-button" className="w-full md:w-auto text-base md:text-lg text-white uppercase tracking-wider px-12 py-3">
                        {doc.data.button_name_1_hover}
                      </StyledButtonOne>
                    </Link>
                  </div>
                </>
              }
              
              {doc.data.button_two_link_type
                ?
                <Link to={doc.data.button_link_2.uid ? `/${doc.data.button_link_2.uid}` : "/"}>
                  <StyledButtonTwo
                    className="w-full md:w-auto opacity-70 hover:opacity-90 text-base md:text-lg tracking-wider px-8 py-3"
                  >
                    {doc.data.button_name_2}
                  </StyledButtonTwo>
                </Link>                
                :
                <StyledButtonTwo
                  onClick={() => {
                    const element = document.getElementById('831b-plans');
                    if (element) {
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                  className="w-full md:w-auto opacity-70 hover:opacity-90 text-base md:text-lg tracking-wider px-4 md:px-3 xl:px-8 py-3"
                >
                  {doc.data.button_name_2}
                </StyledButtonTwo>
              }
            </div>            
          </div>
        </div>
      </div>
    </>
  )
}

HeroHomeUpdateNewVersion.propTypes = {
  agentPage: PropTypes.bool,
}

export const onRenderBody = ({ setPostBodyComponents }) => {
  setPostBodyComponents([    
    <script
      key="custom-script"
      dangerouslySetInnerHTML={{
        __html: `
          window.addEventListener('load', (event) => {
            document.getElementById('qualify-button').addEventListener('click', function() {
              window.cg_conversion = true;
            });

            document.getElementById('testimonials-button').addEventListener('click', function() {
              window.cg_conversion = true;
            });
          });
        `,
      }}
    />,
  ]);
};

export default HeroHomeUpdateNewVersion
